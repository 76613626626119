@import url('https://fonts.googleapis.com/css2?family=McLaren&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

body #root {
    background-color: #0D0D0D;
}

.navbar {
    background-color: #111111;
    padding-top: 5%;
    padding-bottom: 5%;
    height: fit-content;
    justify-content: center;
}

.navbar a {
    color: #F7EFE5;
    font-family: 'Montserrat', sans-serif;                                     
    font-weight: 600;
    letter-spacing: 4px;
    font-size: 1rem;
    text-decoration: none; 
    text-transform: uppercase;  
}

.navbar a:hover {
    color: #435B66;
}

.navbar .nav-item {
    padding-top: 2%;
    padding-bottom: 2%;
    margin-right: 2.25%;
}

.menu-button {
    float: right;
    display: block;
    width: fit-content;
    height: 100%;
    margin-right: 2.5%;
    left: 0;
    right: 0;
}


.navbar-toggler-icon{
    background-image:url('../img/white-menu-icon-4.png') !important;
}

.navbar-brand {
    padding-left: 1.5%;
    position: relative;
}

#dropdown-basic {
    text-transform: uppercase !important;
}

.dropdown-menu {
    text-align: center !important;
}

.dropdown-item {
    color: #435B66 !important;
}

.brand-icon {
    width: 240px;
    height: 100%;
    overflow: hidden;
}


#footer {
    position: relative;
    display: block;
    width: 100%;
    bottom: 0;
    margin-top: auto;
    background-color: #111111;
    font-family: 'Montserrat', sans-serif;           
    font-size: 1rem;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
 }

.icon-padding {
    padding-left: 1.5%;
    padding-right: 1.5%;
    color: #F7EFE5;
    font-size: 1.5rem;
}

.icon-padding:hover {
    color: #435B66;
}

.link-color {
    color: #F7EFE5;
}

.paragraph-style {
    font-family: 'Montserrat', sans-serif;                                       
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 1.2rem;
    padding-bottom: 5%;
    text-align: center;
}

#statement {
    padding-left: 1.1%;
    padding-right: 1.1%;
    text-align: center;
    justify-content: center;
    color: #F7EFE5;
    justify-items: center;
    align-self: center;                                      
    padding-top: 5%;
}

.statement-style {
    font-family: 'Montserrat', sans-serif; 
    font-weight: 400;                                          
    text-decoration: none;
    text-align: left;
    text-transform: uppercase;
}

#statement img {
    width: 100%;
    height: fit-content;
}

#statement p {
    margin-top: 2.5%;
    padding-bottom: 2.5%;
    font-family: 'Montserrat', sans-serif;           
    color: #F7EFE5;
    text-align: left;
    word-spacing: 1.25px;
    letter-spacing: 1px;
}

.icon-size {
    font-size: 3rem;
    font-stretch:narrower;
    color: #F7EFE5;
    margin-bottom: 5%;
}

.info-spacing {
    padding-bottom: 5%;
}

.info-text {
    font-family: 'Montserrat', sans-serif;          
    font-weight: 200;
    text-decoration: none;
}


.align-items-center {
    display: flex; 
    align-items: center;  /*Aligns vertically center */
    justify-content: center; /*Aligns horizontally center */
}

#works {
    padding-bottom: 5%;
    margin-top: 5%;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-self: center;
    color: #F7EFE5;
    font-family: 'Montserrat', sans-serif;          

}

#works video {
    width: 100%;
    height: max-content;
    padding: 0;
    margin-top: 1%;
    border: none;
}

#works p {
    font-family: 'Montserrat', sans-serif;          
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 2%;
    text-align: justify;
    word-spacing: 1.25px;
    letter-spacing: 1px;
}

#works h1 {
    font-family: 'Montserrat', sans-serif;          
    font-size: 2rem;
    font-weight: 800;
    margin-top: 2%;
    margin-bottom: 2%;
    text-align: center;
    word-spacing: 1.25px;
    letter-spacing: 1px;
}

.products {
    margin-bottom: 2%;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}

.app-image {
    padding-bottom: 2%;
}

.app-image img {
    padding-top: 1%;
    padding-bottom: 1%;
    width: 480px;
    height: 100%;
}

.yarl__slide_description {
    text-align: center !important;
}

/* .carousel {
    margin-top: 2%;
    background-color:  	#181818;
    text-shadow: 1px 1px #111111;
    border: 0;
    font-size: 1rem;
    font-weight: 200;
}

.carousel img {
    width: 19.375rem;
    height: 38.125rem;
} */

.embed-Video {
    margin-top: 2%;
    position: relative;
    width: 100% !important;
    height: 720px;
}

#contact {
    font-family: 'Montserrat', sans-serif;           
    font-weight: 200;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
    text-transform: uppercase;

}

#contact h5 {
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;           
    color: #F7EFE5;
}

#contact a {
    text-decoration: none;
    
    
}

#contact a:hover {
    text-decoration: underline;
    color: #FFFBF5;
}

#contact p {
    padding-top: 5%;
    color: #F7EFE5;
}

.contact-text {
    padding-bottom: 5%;
    color: #F7EFE5;
    font-family: 'Montserrat', sans-serif;          
    font-weight: 200;
}

#contact svg {
    color: #F7EFE5;
}

.contact-info {
    padding-bottom: 5%;
}


.google-maps {
    position: relative;
    padding-bottom: 75%; 
    height: 0;
    overflow: hidden;
}

.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.map{
    height: 100vh;
    border: 0; 
    filter: invert(90%);
}


@media (max-width: 768.00px) {
   

    .app-image img {
        width: 240px;
        height: 100%;
    }
    .embed-Video {
        margin-top: 2%;
        position: relative;
        width: 100% !important;
        height: auto !important;
    }
    .icon-padding {
        padding-left: 3%;
        padding-right: 3%;
    }
    .brand-icon {
        width: 140px;
        height: 100%;
        display: block;
    }
    .navbar-toggle-button {
        margin-right: 4.5% !important;
    }
    .navbar-brand {
        padding-left: 6.5%;
    }
    .carousel img {
        width: 9.688rem;
        height: 18rem;
    }
    .carousel h6 {
        font-size: 0.75rem;
    }
    #statement {
        padding-left: 5%;
        padding-right: 5%;
        font-size: 0.85rem;
    }
    #works {
        padding-bottom: 5%;
        padding-top: 5%;

    }
    #works p {
        font-size: 0.85rem;
        text-align: justify;
        margin-top: 5%;
    }
    #works h1 {
        font-size: 1.25rem;
        margin-top: 1%;
        margin-bottom: 5%;
        font-weight: 800;
    }
    #contact {
        padding-left: 5%;
        padding-right: 5%;
        font-size: 0.85rem;
        margin-top: 2%;
        margin-bottom: 4%;
    }
    .dropdown-menu-end {
        width: 100%;
        padding-left: -1rem;
        padding-right: -1rem;
    }
  }

  @media (max-width: 290.00px) {
    .brand-icon {
        width: 120px;
        height: 100%;
        display: block;
    }
  }

  
  @media (max-width: 232.00px) {
    .brand-icon {
        width: 100px;
        height: 100%;
        display: block;
    }
  }

